import { useState } from 'react';

const Step8 = ({setStep}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && (code.toLowerCase().trim().replaceAll(" ", "") === "iputmyspellinyou" || code.toLowerCase().trim().replaceAll(" ", "") === "iputaspellinyou")) {
            setStep(8.9);
            setErr(undefined);
        } else {
            setErr("De spreuk is niet juist");
        }
    }

    return (
        <div>
            <p>Zigorius de Tovenaar is in een wilde toverbui zijn toverstaf kwijt geraakt. Help jij hem deze terug te vinden? Zijn toverstaf zal jullie verder helpen om zijn magische spreuk in te vullen.</p>
            <label>
                Spreuk: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Volgende</button>
        </div>
    )
}

export default Step8;