import { useState } from 'react';

const Step0b = ({setStep, img}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && code.toLowerCase().trim() === "techno") {
            setStep(0.9);
            setErr(undefined);
        } else {
            setErr("De code is niet juist");
        }
    }

    return (
        <div>
            <p>Welkom op de grote druïdentocht. Onze druïden zullen jullie verder helpen met instructies om de weg te vinden. Vraag het codewoord aan Jens om te starten.</p>
            <label>
                Codewoord: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Starten</button>
        </div>
    )
}

export default Step0b;