import { useState } from 'react';

const Step2 = ({setStep}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && (code.toLowerCase().trim().replaceAll(" ", "") === "armworstelaar" || code.toLowerCase().trim().replaceAll(" ", "") === "armworstelen")) {
            setStep(2.9);
            setErr(undefined);
        } else {
            setErr("De code is niet juist");
        }
    }

    return (
        <div>
            <p>Eldrin Ironwood leeft op grote voet. Ook al is hij van stad Berrevoesj, heeft hij zijn schoenen nodig. Help jij hem verder? Dan vind je de code.</p>
            <label>
                Codewoord: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Volgende</button>
        </div>
    )
}

export default Step2;