import { useState } from 'react';

const Step5 = ({setStep}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && (code.toLowerCase().trim().replaceAll(" ", "").replaceAll("c","k") === "tweetakt" || code.toLowerCase().trim().replaceAll(" ", "").replaceAll("c","k") === "2takt")) {
            setStep(5.9);
            setErr(undefined);
        } else {
            setErr("Het woord is niet juist");
        }
    }

    return (
        <div>
            <p>De toverdranken van Panoramix moeten koel bewaard worden zodat ze lang goed blijven. Henjand the Horny komt daarvoor een koelcel installeren. Voorlopig moeten we op een andere manier koelen…</p>
            <label>
                Koel woord: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Volgende</button>
        </div>
    )
}

export default Step5;