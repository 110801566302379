import './App.css';
import logo from './img/logo.png';
import { useState, useEffect } from 'react';
import StepHeader from './steps/header';
import Step0 from './steps/step0';
import Step0b from './steps/step0b';
import Navigate from './steps/navigate';
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import Step5 from './steps/step5';
import Step6 from './steps/step6';
import Step7 from './steps/step7';
import Step8 from './steps/step8';
import Step9 from './steps/step9';
import Step10 from './steps/step10';
import StepError from './steps/error';
import img01 from './img/image01.jpeg';
import imgnav from './img/navigate.jpeg';
import img1 from './img/image1.jpg';
import img2 from './img/image2.jpg';
import img3 from './img/image3.jpg';
import img4 from './img/image4.jpg';
import img5 from './img/image5.jpg';
import img6 from './img/image6.jpg';
import img7 from './img/image7.jpg';
import img8 from './img/image8.jpg';
import img9 from './img/image9.jpg';
import img10 from './img/image10.jpeg';
import ConfettiExplosion from 'react-confetti-explosion';
import GpxParser from 'gpxparser';

function App() {
  const [step, setStep] = useState(0);
  const [coords, setCoords] = useState();
  const [pageReady, setPageReady] = useState(false);

  useEffect(() => {
    window.onbeforeunload = function() {
        return "Peken, niet refreshen. Ge hebt hier geen internet.";
    }
    if(coords === undefined) {
        fetch("Druiden.gpx").then((r)=> {
            if(r.ok) {
                r.text().then(d=> {
                    var gpx = new GpxParser();
                    gpx.parse(d);
                    setCoords(
                        gpx.waypoints.map(wp => ({x: wp.lat, y: wp.lon}))
                    );
                })
            }
        });
    }
    const onPageLoad = () => {
        setPageReady(true);
    };
    if (document.readyState === 'complete') {
        onPageLoad();
    } else {
        window.addEventListener('load', onPageLoad, false);
        return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  function renderStep() {
    switch(step) {
        case 0:
            return <Step0 setStep={setStep}/>;
        case 0.1:
            return <Step0b setStep={setStep}/>;
        case 0.9:
            return <Navigate nextStep={1} locationName={"Heroix"} x={coords[0].x} y={coords[0].y} setStep={setStep}/>;
        case 1:
            return <Step1 setStep={setStep}/>;
        case 1.9:
            return <Navigate nextStep={2} locationName={"Eldrin Ironwood"} x={coords[1].x} y={coords[1].y} setStep={setStep}/>;
        case 2:
            return <Step2 setStep={setStep}/>;
        case 2.9:
            return <Navigate nextStep={3} locationName={"Waldorix"} x={coords[2].x} y={coords[2].y} setStep={setStep}/>;
        case 3:
            return <Step3 setStep={setStep}/>;
        case 3.9:
            return <Navigate nextStep={4} locationName={"Panoramix"} x={coords[3].x} y={coords[3].y} setStep={setStep}/>;
        case 4:
            return <Step4 setStep={setStep}/>;
        case 4.9:
            return <Navigate nextStep={5} locationName={"Henjand the Horny"} x={coords[4].x} y={coords[4].y} setStep={setStep}/>;
        case 5:
            return <Step5 setStep={setStep}/>;
        case 5.9:
            return <Navigate nextStep={6} locationName={"Merlijn"} x={coords[5].x} y={coords[5].y} setStep={setStep}/>;
        case 6:
            return <Step6 setStep={setStep}/>;
        case 6.9:
            return <Navigate nextStep={7} locationName={"Bigdickvulvariovix"} x={coords[6].x} y={coords[6].y} setStep={setStep}/>;
        case 7:
            return <Step7 setStep={setStep}/>;
        case 7.9:
            return <Navigate nextStep={8} locationName={"Zigorius de Tovenaar"} x={coords[7].x} y={coords[7].y} setStep={setStep}/>;
        case 8:
            return <Step8 setStep={setStep}/>;
        case 8.9:
            return <Navigate nextStep={9} locationName={"Robby Black Magic"} x={coords[8].x} y={coords[8].y} setStep={setStep}/>;
        case 9:
            return <Step9 setStep={setStep}/>;
        case 9.9:
            return <Navigate nextStep={10} locationName={"de finale"} x={coords[9].x} y={coords[9].y} setStep={setStep}/>;
        case 10:
            return <Step10/>
        default:
            return <StepError/>
    }
  }

  return (
    <div className="App">
      <header className="header">
          <img src={logo} className="logo" alt="logo" />
          <b>Druïden in de Ardennen</b>
          <span className={pageReady && coords && coords.length === 10 ? "ready" : "loading"}>●</span>
      </header>
      <div className="stepContainer">
            <StepHeader step={step}/>
            {step > 1 && step % 1 > 0.5 && <ConfettiExplosion/>}
            <img src={imgnav} className={step === 0 || step % 1 > 0.5 ? "stepImg" : "invImg"} alt="step" />
            <img src={img01} className={step === 0.1 ? "stepImg" : "invImg"} alt="step" />
            <img src={img1} className={step === 1 ? "stepImg" : "invImg"} alt="step" />
            <img src={img2} className={step === 2 ? "stepImg" : "invImg"} alt="step" />
            <img src={img3} className={step === 3 ? "stepImg" : "invImg"} alt="step" />
            <img src={img4} className={step === 4 ? "stepImg" : "invImg"} alt="step" />
            <img src={img5} className={step === 5 ? "stepImg" : "invImg"} alt="step" />
            <img src={img6} className={step === 6 ? "stepImg" : "invImg"} alt="step" />
            <img src={img7} className={step === 7 ? "stepImg" : "invImg"} alt="step" />
            <img src={img8} className={step === 8 ? "stepImg" : "invImg"} alt="step" />
            <img src={img9} className={step === 9 ? "stepImg" : "invImg"} alt="step" />
            <img src={img10} className={step === 10 ? "stepImg" : "invImg"} alt="step" />
            {renderStep()}
      </div>
    </div>
  );
}

export default App;
