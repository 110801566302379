
const Step10 = () =>  {

    return (
        <div>
            <p>Proficiat! Jullie hebben de grote druïdentocht succesvol tot een einde gebracht! Als beloning mogen jullie nu potions proeven.</p>
            <p>Vond je het plezant? Voel je vrij om een 5 sterren beoordeling te geven op Devacto met deze link</p>
        </div>
    )
}

export default Step10;