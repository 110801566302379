const StepError = ({setStep}) => {

    function reset() {
        setStep(0.1);
    }

    return (
        <div className="stepContainer">
            <header>Oei...</header>
            <p>Er is iets fout gegaan. Klik om te herbeginnen.</p>
            <button onClick={() => reset()}>Herbeginnen</button>
        </div>
    )
}

export default StepError;