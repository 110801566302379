import { useState } from 'react';

const Step9 = ({setStep}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && code.toLowerCase().trim().replaceAll(" ", "") === "1869") {
            setStep(9.9);
            setErr(undefined);
        } else {
            setErr("De pincode is niet juist. Enkel cijfers.");
        }
    }

    return (
        <div>
            <p>“Help mij”, horen we Robby Black Magic roepen vanuit Thailand. Hij is de pin-code van zijn credit card kwijt. Help jij hem betalen zodat hij vanavond nog kan toveren in Pattaya?</p>
            <label>
                Pincode: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Volgende</button>
        </div>
    )
}

export default Step9;