import { useState } from 'react';
import findUserLocation from './../util/geo';

const released = false; // TODO: change

const Step0 = ({setStep}) => {
    const [err, setErr] = useState();
    const [dis, setDis] = useState();

    findUserLocation().then(
        console.log,
        () => {setErr("Je moet toegang geven tot je locatie");}
    );

    function verifyAndNext() {
        setDis(true);
        findUserLocation().then(
            () => {
                if(released) {
                    setErr(undefined);
                    setStep(0.1);
                    window.alert("Belangrijk: pagina niet refreshen aub!");
                } else {
                    setErr("Merci! Nu wachten tot 7 december")
                    window.alert("Merci! Nu wachten tot 7 december. Tot in de Ardennen boys 🧙");
                    setDis(true);
                }
            },
            () => {
                setErr("Je moet toegang geven tot je locatie");
            }
        )
        .finally(setDis(false));
    }

    return (
        <div>
            <p>Voorbereiding:</p>
            <ol>
                <li>Geef toegang tot je locatie</li>
                <li>Installeer de app <a href="https://organicmaps.app/#install" target="_blank">Organic Maps</a></li>
                <li>Klik <a href='om://map?v=1&ll=49.824375,5.107502&n=Druidennest&appname=DruidenArdennen' target='_blank'>hier</a> en download provincie Luxemburg</li>
                <li>Verkleed u als druïde</li>
            </ol>
            {err && <p className="error">{err}</p>}
            <button onClick={() => verifyAndNext()} disabled={dis}>Ik ben klaar</button>
        </div>
    )
}

export default Step0;