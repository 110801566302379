const names = [
    "start van de zoektocht",
    "Heroix",
    "Eldrin Ironwood",
    "Waldorix",
    "Panoramix",
    "Henjand the Horny",
    "Merlijn",
    "Bigdickvulvariovix",
    "Zigorius de Tovenaar",
    "Robby Black Magic",
    "finale"
];

const StepHeader = ({step}) => {
    const roundedStep = Math.round(step);

    if(step === 10) {
        return <header>Proficiat!!!</header>
    } else {
        return <header>Stap {roundedStep}/10:{roundedStep%10 === 0 ? '' : step%1 > 0.5 ? ' op weg naar' :' bij'} {names[roundedStep]}</header>;
    }
}

export default StepHeader;