import { useState } from 'react';

const Step4 = ({setStep}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && code.toLowerCase().trim().replaceAll(" ", "") === "damiana") {
            setStep(4.9);
            setErr(undefined);
        } else {
            setErr("Het ingrediënt is niet juist. 7 letters.");
        }
    }

    return (
        <div>
            <p>Panoramix is weer een geestverruimend kruidenmengsel aan het maken. Het mengsel is bijna klaar, maar er ontbreekt een deel van zijn recept! Combineer en vind het ontbrekende ingrediënt.</p>
            <label>
                Ingrediënt: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Volgende</button>
        </div>
    )
}

export default Step4;