const findUserLocation = () => {
    if (navigator.geolocation) {
        return new Promise((succ, err) => {
            navigator.geolocation.getCurrentPosition(
                succ,
                err,
                {
                    enableHighAccuracy: true,
                    maximumAge: 2000,
                    timeout: 5000
                }
            )
        });
    }
    else {
        window.alert("Je browser kan geen locatie delen :(");
        console.error('Geolocation is not supported by this browser :(');
    }
};

export default findUserLocation;