import { useState } from 'react';
import findUserLocation from './../util/geo';

const Navigate = ({nextStep, locationName, x, y, setStep}) => {
    const [err, setErr] = useState();
    const [dis, setDis] = useState();
    const allowedXtollerance = 0.00025;
    const allowedYtollerance = 0.00018;

    const deepLink = `om://map?v=1&ll=${x},${y}&n=${locationName.replaceAll(" ","%20")}&appname=DruidenArdennen`;

    function verifyAndNext() {
        setDis(true);
        findUserLocation().then(
            (position) => {
                let coords = position.coords;
                console.log(coords);
                if(Math.abs(coords.latitude - x) > allowedXtollerance || Math.abs(coords.longitude - y) > allowedYtollerance) {
                    setErr("Leugens! Ga dichter bij de locatie.");
                } else {
                    setErr(undefined);
                    setStep(nextStep);
                }
            },
            () => {
                setErr("Je moet toegang geven tot je locatie");
            }
        )
        .finally(setDis(false));
    }

    return (
        <div>
            <p>Navigeer naar <a href={deepLink} target="_blank">deze locatie</a> (negeer streetview).</p>
            <p>Hier wacht {locationName} op jullie.</p>
            {err && <p className="error">{err}</p>}
            <button onClick={() => verifyAndNext()} disabled={dis}>Ik ben er</button>
        </div>
    )
}

export default Navigate;