import { useState } from 'react';

const Step3 = ({setStep}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && (code.toLowerCase().trim().replaceAll(" ", "") === "polen" || code.toLowerCase().trim().replaceAll(" ", "") === "pools" || code.toLowerCase().trim().replaceAll(" ", "") === "poolse")) {
            setStep(3.9);
            setErr(undefined);
        } else {
            setErr("De code is niet juist");
        }
    }

    return (
        <div>
            <p>Waldorix is een man van veel lawaai. Om zijn schat te vinden, moeten we hem zien stil te krijgen. Hij heeft een tracker verstopt in zijn territorium. Wie goed luistert, zal zien. 🤫</p>
            <label>
                Codewoord: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Volgende</button>
        </div>
    )
}

export default Step3;