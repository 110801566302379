import { useState } from 'react';

const Step6 = ({setStep}) => {
    const [code, setCode] = useState("");
    const [err, setErr] = useState();

    function checkCode() {
        if(code && (code.toLowerCase().trim().replaceAll(" ", "").replaceAll("the", "") === "firstrebirth" || code.toLowerCase().trim().replaceAll(" ", "").replaceAll("the", "") === "firstrebirt")) {
            setStep(6.9);
            setErr(undefined);
        } else {
            setErr("De titel is niet juist");
        }
    }

    return (
        <div>
            <p>Merlijn draait al eens graag een plaatje. Hij heeft een dikke schijf gehoord waarvan hij graag de titel weet. Hij helpt jullie graag verder in ruil voor de titel.</p>
            <label>
                Titel: <input name="code" onChange={e => setCode(e.target.value)} value={code}/>
            </label>
            {err && <p className="error">{err}</p>}
            <button onClick={() => checkCode()}>Volgende</button>
        </div>
    )
}

export default Step6;